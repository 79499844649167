import { Button, CartItemCTA, Text } from '@/atoms';
import { PRODUCT_TYPE, staticMediaStoreBaseURL } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/helpers/formatNumber';
import LineItemMedia from '@/molecules/commonCart/LineItemMedia';
import { getSelectedPartner } from '@/services/plannerCart.service';

const UnavailableCartItemOrganism = ({
  haflaMarginPercent,
  id,
  itemMediaForCarousel,
  partnerId,
  product: {
    name,
    price: { minPrice, price },
    productPartners,
    slug,
    type: productType
  },
  quantity,
  sellingPrice,
  setCartItemIdForUploadMediaModal,
  showProductMedia,
  unitListedPrice,
  isCartEditable,
  onDeleteCartItemImage,
  updateCartItemMediaSortOrder
}) => {
  const unitPriceBasedOnProductTypeMap = {
    [PRODUCT_TYPE.QUOTE]: `Price starting from AED ${parseFormatPriceValueFromAPI(
      Number(minPrice)
    )}`,
    [PRODUCT_TYPE.CART]: parseFormatPriceValueFromAPI(Number(price || 0))
  };

  return (
    <div className='w-full'>
      <div
        className={`flex gap-5 text-sm font-light py-3 ${
          isCartEditable ? 'border-b' : ''
        } px-4`}
      >
        <div className='flex-1 flex'>
          <LineItemMedia
            {...{
              cartItemId: id,
              isCartEditable,
              itemMediaForCarousel,
              onDeleteCartItemImage,
              productName: name,
              setCartItemIdForUploadMediaModal,
              showProductMedia,
              updateCartItemMediaSortOrder,
              unAvailableCartItem: true
            }}
          />
          <a
            href={`/products/${slug}`}
            className='self-center'
          >
            <Text
              {...{
                content: name,
                className: 'flex-1 flex text-red-600 ml-5'
              }}
            />
          </a>
        </div>
        <Text
          {...{
            content: unitPriceBasedOnProductTypeMap[productType],
            className: 'w-40 self-center text-red-600'
          }}
        />
        <Text
          {...{
            content: quantity,
            className: 'w-20 self-center text-red-600'
          }}
        />
        <Text
          {...{
            content: parseFormatPriceValueFromAPI(unitListedPrice),
            className: 'w-24 self-center text-red-600'
          }}
        />
        <Text
          {...{
            content: parseFormatPriceValueFromAPI(sellingPrice),
            className: 'w-30 self-center text-red-600'
          }}
        />
        <Text
          {...{
            content: getSelectedPartner({ productPartners, partnerId }),
            className: 'w-40 self-center text-red-600'
          }}
        />
        <Text
          {...{
            content: `${parseFormatPriceValueFromAPI(haflaMarginPercent)} %`,
            className: 'w-20 self-center text-red-600'
          }}
        />

        <div className='w-40 self-center flex gap-3'>
          <Button
            {...{
              children: 'View',
              width: 'w-20',
              className: `opacity-40 pointer-events-none bg-gradient-to-r h-8 from-light-orange via-dark-orange
                 to-rose text-white px-2 py-2 rounded-lg text-sm font-medium`,
              iconGroupClassName: 'reduce-gap',
              iconHeight: 15,
              iconPosition: 'Left',
              iconUrl: `${staticMediaStoreBaseURL}/icons/view-icon.svg`,
              iconWidth: 15
            }}
          />
          <CartItemCTA
            {...{
              type: 'delete',
              className: 'self-center opacity-40 pointer-events-none'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UnavailableCartItemOrganism;
