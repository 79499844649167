import { useEffect, useRef } from 'react';

import { Divider, ProductPrice } from '@/atoms';
import phoneConfig from '@/config/phone';
import {
  ProductDescription,
  ProductDetail,
  ProductInfo,
  ProductNotes,
  ProductQuantityRangeAlertError
} from '@/molecules';

const { phoneNumbers } = phoneConfig;

const ProductSummaryDetailOrganism = ({ product, showQuantityRangeAlert }) => {
  const { description, displayAttributes, name, note, shortDescription } =
    product;
  const showErrorRef = useRef();

  useEffect(() => {
    showQuantityRangeAlert && showErrorRef.current.scrollIntoViewIfNeeded();
  }, [showQuantityRangeAlert]);

  return (
    <div className='product-info w-full md:w-2/3 px-5 md:pr-20 md:pl-0'>
      <ProductInfo {...{ name, shortDescription }} />
      <div className='block md:hidden my-5'>
        <ProductPrice {...{ product }} />
        {showQuantityRangeAlert && (
          <ProductQuantityRangeAlertError
            {...{
              ...product,
              className: 'block md:hidden',
              phoneNumbers,
              showErrorRef
            }}
          />
        )}
      </div>
      <Divider />
      {Boolean(
        description?.[0]?.children[0]?.children?.[0]?.text ||
          description?.[0]?.children?.[0]?.text
      ) && (
        <>
          <ProductDescription {...{ description }} />
          <Divider {...{ space: 'mt-8 mb-6' }} />
        </>
      )}
      {displayAttributes.length > 0 && (
        <>
          <ProductDetail {...{ displayAttributes }} />
          <Divider {...{ space: 'mt-8 mb-6' }} />
        </>
      )}
      {note?.[0]?.children &&
        Boolean(
          note[0].children?.[0]?.children?.[0].text ||
            note[0].children?.[0].text
        ) && <ProductNotes {...{ note }} />}
    </div>
  );
};

export default ProductSummaryDetailOrganism;
