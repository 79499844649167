import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Button, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { convertToDateFormat } from '@/lib/time';
import EditableCartSummaryModal from '@/molecules/myEvents/modal/EditableCartSummaryModal';

const ViewEventCTA = ({ eventId }) => (
  <div className='flex bg-white text-brand border border-orange py-0 rounded-lg text-sm font-medium h-8'>
    <Link href={`/my-events/${eventId}/event-cart-details`}>
      <div className='flex px-3 cursor-pointer'>
        <Image
          {...{
            alt: 'view event',
            height: 16,
            src: `${staticMediaStoreBaseURL}/icons/view.svg`,
            width: 16
          }}
        />
        <Text {...{ content: 'Events', className: 'self-center pl-1' }} />
      </div>
    </Link>
  </div>
);

const ViewUserEventCTA = ({
  cartName,
  cartNumber,
  createdAt,
  deliveryDate,
  eventId,
  id,
  isDefault,
  isReadOnly,
  pickupDate,
  setEditCartSummaryModal,
  showCartEventInfo,
  status,
  updatedAt
}) => (
  <div className='self-center flex gap-3 md:gap-3 xl:gap-5'>
    {!isDefault && (
      <>
        {!isReadOnly && (
          <>
            <ViewEventCTA {...{ eventId }} />
            <Button
              {...{
                children: 'Cart',
                className:
                  'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white px-3 py-1 rounded-lg text-sm font-medium',
                iconGroupClassName: 'reduce-gap',
                iconHeight: 18,
                iconPosition: 'Left',
                iconUrl: `${staticMediaStoreBaseURL}/icons/edit-icon-white.svg`,
                iconWidth: 18,
                onClick: (e) => {
                  e.stopPropagation();
                  setEditCartSummaryModal({
                    showModal: true,
                    cartSummary: {
                      cartName,
                      cartNumber,
                      createdAt,
                      deliveryDate,
                      id,
                      pickupDate,
                      status,
                      updatedAt
                    }
                  });
                },
                width: 'w-24'
              }}
            />
          </>
        )}
        <div className='self-center'>
          <Image
            {...{
              alt: 'show event info',
              className: `cursor-pointer transform ${
                showCartEventInfo ? 'rotate-180' : 'rotate-0'
              }`,
              height: 15,
              layout: 'fixed',
              src: `${staticMediaStoreBaseURL}/icons/down.svg`,
              width: 15
            }}
          />
        </div>
      </>
    )}
  </div>
);

const HeaderCartDetails = ({
  cartName,
  cartNumber,
  deliveryDateToShow,
  orderId,
  orderNumber,
  pickupDateToShow,
  status
}) => {
  const Router = useRouter();
  return (
    <div className='flex flex-col md:flex-row gap-3'>
      <div className='flex md:self-center gap-2'>
        <Text {...{ content: cartName, className: 'max-w-50 truncate' }} />
      </div>
      <div className='flex gap-2 md:self-center'>
        <Text
          {...{
            content: 'Cart No:',
            className: 'text-silver w-28 md:w-auto'
          }}
        />
        <Text {...{ content: cartNumber }} />
      </div>
      {orderNumber && (
        <div className='flex gap-2 md:self-center cursor-pointer'>
          <Text
            {...{
              content: 'Order No:',
              className: 'text-silver w-28 md:w-auto'
            }}
          />
          <Text
            {...{
              onClick: () => {
                Router.push(`/my-orders/${orderId}/order-details`);
              },
              content: orderNumber,
              className: 'text-blue cursor-pointer'
            }}
          />
        </div>
      )}
      <div className='hidden md:flex gap-2 self-center'>
        <Text
          {...{
            content: 'Status:',
            className: 'text-silver w-24 md:w-auto self-center'
          }}
        />
        {status}
      </div>
      <div className='hidden md:flex gap-2 self-center'>
        <Text
          {...{
            content: 'Delivery Date:',
            className: 'text-silver w-24 md:w-auto self-center'
          }}
        />
        {deliveryDateToShow &&
          convertToDateFormat(deliveryDateToShow, 'DD MMM YYYY')}
      </div>
      <div className='hidden md:flex gap-2 self-center'>
        <Text
          {...{
            content: 'Pickup Date:',
            className: 'text-silver w-24 md:w-auto self-center'
          }}
        />
        {pickupDateToShow &&
          convertToDateFormat(pickupDateToShow, 'DD MMM YYYY')}
      </div>
    </div>
  );
};
const CartInfoSummary = ({
  cartName,
  cartNumber,
  createdAt,
  deliveryDate,
  eventId,
  id,
  isDefault,
  isReadOnly,
  orderId,
  orderNumber,
  pickupDate,
  setEditCartSummaryModal,
  setShowCartEventInfo,
  showCartEventInfo,
  status,
  updatedAt
}) => {
  const deliveryDateToShow = deliveryDate || '';
  const pickupDateToShow = pickupDate || '';
  return (
    <div
      onClick={() => setShowCartEventInfo(!showCartEventInfo)}
      className={`flex flex-wrap justify-between ${
        !isDefault && showCartEventInfo ? 'pb-6' : 'pb-0'
      } `}
    >
      <HeaderCartDetails
        {...{
          cartName,
          cartNumber,
          deliveryDateToShow,
          orderId,
          orderNumber,
          pickupDateToShow,
          status
        }}
      />
      <ViewUserEventCTA
        {...{
          cartName,
          cartNumber,
          createdAt,
          deliveryDate,
          eventId,
          id,
          isDefault,
          isReadOnly,
          pickupDate,
          setEditCartSummaryModal,
          showCartEventInfo,
          status,
          updatedAt
        }}
      />
    </div>
  );
};

const CartInfoSummaryOrganism = ({
  cartName,
  cartNumber,
  createdAt,
  deliveryDate,
  editCartSummaryModal,
  id,
  isDefault,
  isReadOnly,
  orderId,
  orderNumber,
  pickupDate,
  setEditCartSummaryModal,
  setShowCartEventInfo,
  setUpdateCartDetail,
  showCartEventInfo,
  status,
  updatedAt,
  userEventSummary
}) => {
  if (!id) return '';
  const eventDate = userEventSummary?.userEventDetails?.[0]?.eventDate || '';
  const isZendeskIdAgentNameAvailable = Boolean(
    userEventSummary.zendeskTicketId &&
      userEventSummary?.userEventDetails?.[0]?.opsUser?.name
  );

  return (
    <div>
      {!isDefault && editCartSummaryModal.showModal && (
        <EditableCartSummaryModal
          {...{
            editCartSummaryModal,
            eventDate,
            isZendeskIdAgentNameAvailable,
            setEditCartSummaryModal,
            setUpdateCartDetail,
            userEventId: userEventSummary?.id,
            userEvent: { ...userEventSummary }
          }}
        />
      )}
      <CartInfoSummary
        {...{
          cartName,
          cartNumber,
          createdAt,
          deliveryDate,
          eventDate,
          eventId: userEventSummary.id,
          id,
          isDefault,
          isReadOnly,
          orderId,
          orderNumber,
          pickupDate,
          setEditCartSummaryModal,
          setShowCartEventInfo,
          showCartEventInfo,
          status,
          updatedAt
        }}
      />
    </div>
  );
};

export default CartInfoSummaryOrganism;
