import { UnSavedChangesErrorMessage } from '@/atoms';
import { PRICE_SUMMARY_HEADERS, VIEW_EDIT_MODE } from '@/config/plannerCart';
import {
  CartSectionHeading,
  PriceSummaryFooter,
  PriceSummaryHeader,
  PriceSummaryRow
} from '@/molecules';

const PriceSummaryOrganism = ({
  getValues,
  handleSubmit,
  isCartEditable,
  priceSummaryViewEditMode,
  register,
  saveChanges,
  setPriceSummaryViewEditMode,
  showUnSavedChangesErrorMessage,
  summaryFooterItem,
  summaryItems,
  title
}) => {
  const onClickEdit = () => setPriceSummaryViewEditMode(VIEW_EDIT_MODE.EDIT);

  const onClickDiscardChanges = () =>
    setPriceSummaryViewEditMode(VIEW_EDIT_MODE.VIEW);

  return (
    <div>
      <div className='my-4'>
        <CartSectionHeading
          {...{
            isCartEditable,
            mode: priceSummaryViewEditMode,
            onClickDiscardChanges,
            onClickEdit,
            onClickSaveChanges: handleSubmit(saveChanges),
            title
          }}
        />
        <UnSavedChangesErrorMessage {...{ showUnSavedChangesErrorMessage }} />
      </div>
      <div className=' border border-platinum rounded-lg mb-4'>
        <PriceSummaryHeader
          {...{ priceSummaryHeader: PRICE_SUMMARY_HEADERS }}
        />
        {summaryItems.map((summaryRow, index) => (
          <PriceSummaryRow
            key={index}
            {...{ ...summaryRow, register, getValues }}
          />
        ))}
        <PriceSummaryFooter {...summaryFooterItem} />
      </div>
    </div>
  );
};

export default PriceSummaryOrganism;
