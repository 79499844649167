import Image from 'next/image';
import { Fragment } from 'react';

import { Text } from '@/atoms';
import { mediaStoreBaseURL, staticMediaStoreBaseURL } from '@/config/common';
import { formatDateToHaflaStandardFormat, formatTimeToAmPm } from '@/lib/time';
import { EventProfileProgressBar, TerminateEvent } from '@/molecules';
import {
  getEPICGenrationCompletionPercentage,
  isEmptyOrDummyEventDetail
} from '@/services/myEvents.service';

const SideBarHead = ({
  displayUserEventProfile,
  isMobile,
  showViewButton,
  userEvent
}) => (
  <div className='flex flex-row md:flex-col gap-4'>
    <div className={`${isMobile ? 'w-20' : 'w-40'} rounded-lg`}>
      <Image
        alt='Event'
        className='rounded-lg'
        height={isMobile ? '80' : '160'}
        id='user-event-icon'
        layout='fixed'
        priority='true'
        src={`${mediaStoreBaseURL}/${userEvent.userEventDetails[0].checkoutEvent.iconUrl}`}
        width={isMobile ? '80' : '160'}
      />
    </div>
    <div className='flex flex-col'>
      <Text
        className='text-nero text-xl md:text-3xl font-semibold'
        content={userEvent.userEventDetails[0].checkoutEvent?.event.name}
      />
      {showViewButton && (
        <div className='flex justify-start mb-2 mt-1'>
          <div
            className='flex gap-4 md:gap-2'
            onClick={displayUserEventProfile}
          >
            <Image
              src={`${staticMediaStoreBaseURL}/icons/view.svg`}
              width={15}
              height={15}
              alt='editIcon'
            />
            <Text
              content='view'
              className='text-brand font-normal cursor-pointer self-start'
            />
          </div>
        </div>
      )}
    </div>
  </div>
);

const SideBarBody = ({
  handleOnClickZendesk,
  isCRM,
  isMobile,
  leadExitReasonErrorMessage,
  leadExitReasons,
  setLeadExitReasonErrorMessage,
  updateLeadExitReasonAPI,
  userEvent
}) => {
  const { zendeskTicketId, leadExitReason, userEventDetails } = userEvent;
  const [{ eventContactName, eventDate, eventStartTime, eventAddress }] =
    userEventDetails;
  const isDummyOrEmptyFieldForCRM = ({ key, value }) =>
    isCRM &&
    isEmptyOrDummyEventDetail({
      key,
      value
    });

  return (
    <Fragment>
      <div className='flex justify-start gap-4 '>
        <div
          className='flex gap-4 self-center'
          onClick={() => handleOnClickZendesk(zendeskTicketId)}
        >
          <Image
            alt='editIcon'
            height={isMobile ? '24' : '28'}
            src={`${staticMediaStoreBaseURL}/icons/zendesk-icon.svg`}
            width={isMobile ? '24' : '28'}
          />
          <a>
            <Text
              className={`text-sm md:text-base font-medium cursor-pointer self-center ${
                zendeskTicketId ? '' : 'text-error-base'
              }`}
              content={zendeskTicketId || 'N/A'}
            />
          </a>
        </div>
      </div>
      <div className='flex justify-start gap-4 my-2'>
        <div className='flex gap-4'>
          <div className='flex self-center'>
            <Image
              alt='editIcon'
              height={isMobile ? '24' : '28'}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/user-red.svg`}
              width={isMobile ? '24' : '28'}
            />
          </div>
          <Text
            className={`font-medium self-center ${
              isDummyOrEmptyFieldForCRM({
                key: 'eventContactName',
                value: eventContactName
              })
                ? 'text-error-base'
                : ''
            }`}
            content={eventContactName}
          />
        </div>
      </div>
      <div className='flex justify-start gap-4 my-2'>
        <div className='flex gap-4 self-center'>
          <Image
            alt='editIcon'
            height={isMobile ? '24' : '28'}
            src={`${staticMediaStoreBaseURL}/icons/calender-red.svg`}
            width={isMobile ? '24' : '28'}
          />
          <Text
            className={`text-sm md:text-base self-center font-medium ${
              isDummyOrEmptyFieldForCRM({
                key: 'eventDate',
                value: formatDateToHaflaStandardFormat(eventDate)
              })
                ? 'text-error-base'
                : ''
            }`}
            content={formatDateToHaflaStandardFormat(eventDate)}
          />
        </div>
      </div>
      <div className='flex justify-start gap-4 my-2'>
        <div className='flex gap-4'>
          <div className='w-6 self-center flex'>
            <Image
              alt='editIcon'
              height={isMobile ? '24' : '28'}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/clock-icon.svg`}
              width={isMobile ? '24' : '28'}
            />
          </div>
          <Text
            className={`text-sm md:text-base self-center flex-1 font-medium ${
              isDummyOrEmptyFieldForCRM({
                key: 'eventStartTime',
                value: eventStartTime
              })
                ? 'text-error-base'
                : ''
            }`}
            content={formatTimeToAmPm(eventStartTime)}
          />
        </div>
      </div>
      <div className='flex justify-start gap-4'>
        <div className='flex gap-4'>
          <div className='w-6'>
            <Image
              alt='editIcon'
              height={isMobile ? '24' : '28'}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/location-icon.svg`}
              width={isMobile ? '24' : '28'}
            />
          </div>
          <Text
            className={`text-sm md:text-base flex-1 font-medium align-center ${
              isDummyOrEmptyFieldForCRM({
                key: 'formattedAddress',
                value: eventAddress?.formattedAddress
              })
                ? 'text-error-base'
                : ''
            }`}
            content={eventAddress?.formattedAddress}
          />
        </div>
      </div>
      {isCRM && (
        <TerminateEvent
          {...{
            leadExitReason,
            leadExitReasonErrorMessage,
            leadExitReasons,
            setLeadExitReasonErrorMessage,
            updateLeadExitReasonAPI
          }}
        />
      )}
    </Fragment>
  );
};

const UserEventCartDetailsSideBarOrganism = ({
  displayUserEventProfile,
  handleOnClickZendesk,
  isCRM,
  isMobile,
  leadExitReasonErrorMessage,
  leadExitReasons,
  setLeadExitReasonErrorMessage,
  updateLeadExitReasonAPI,
  userEvent
}) => {
  const isProfileComplete =
    getEPICGenrationCompletionPercentage(userEvent) === '100.00';
  const showProgressBar = isCRM && !isProfileComplete;

  return (
    <div className='md:w-108 bg-white px-6 py-4 md:pl-24 md:pr-10 md:pt-5 flex flex-col gap-3'>
      <SideBarHead
        {...{
          displayUserEventProfile,
          isMobile,
          showViewButton: isProfileComplete,
          userEvent
        }}
      />

      {showProgressBar && (
        <EventProfileProgressBar
          {...{
            eventDescription:
              'Dummy values exist in the event details; please update the values before proceeding',
            eventHeader: {
              buttonText: 'View',
              eventTitle: 'Complete your profile',
              onClickCTA: displayUserEventProfile
            },
            progressBarPercentage:
              getEPICGenrationCompletionPercentage(userEvent)
          }}
        />
      )}
      <SideBarBody
        {...{
          handleOnClickZendesk,
          isCRM,
          isMobile,
          leadExitReasonErrorMessage,
          leadExitReasons,
          setLeadExitReasonErrorMessage,
          updateLeadExitReasonAPI,
          userEvent
        }}
      />
    </div>
  );
};

export default UserEventCartDetailsSideBarOrganism;
