import { CTA } from '@/atoms';

const RequestedItemListOrganism = ({
  CartItemRow,
  cartItemTitleClass,
  deleteItem,
  itemList = [],
  isCartEditable,
  priceLabelClass
}) => (
  <div className='border border-red-500 px-3 py-4 md:p-6 rounded-2.5 flex flex-col gap-3'>
    {itemList.map((items, index) => (
      <CartItemRow
        key={index}
        {...{
          ...items,
          isCartEditable,
          cartItemTitleClass,
          deleteItem,
          priceLabelClass
        }}
      />
    ))}
    <div className='flex justify-end'>
      <CTA
        {...{
          buttonColor:
            'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white',
          children: 'Waiting for price',
          className: 'text-sm font-medium w-40 py-2',
          disabled: true
        }}
      />
    </div>
  </div>
);

export default RequestedItemListOrganism;
