import Image from 'next/image';
import { useState } from 'react';

import { ConfirmationModal, SpinnerWithInput, Text } from '@/atoms';
import { PRODUCT_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME } from '@/lib/gtm';
import { HostCartProductNotes, LineItemMedia } from '@/molecules';
import { getPriceLabel } from '@/services/hostCart.service';

const RemoveItemIcon = ({ onClick: deleteItem, productStatus }) => {
  const imageSrc = `${staticMediaStoreBaseURL}/icons/${
    productStatus === PRODUCT_STATUS.UNPUBLISHED
      ? 'delete-icon-red.svg'
      : 'delete-icon-gray.svg'
  }`;
  return (
    <div>
      <Image
        {...{
          alt: 'delete',
          className: 'rounded-lg cursor-pointer',
          height: 28,
          onClick: deleteItem,
          src: imageSrc,
          width: 28
        }}
      />
    </div>
  );
};

const CartItemBody = ({
  availableDiscount,
  cartItemTitleClass,
  categories,
  id,
  isCartEditable,
  isSpinnerInputReadOnly,
  maxBookable,
  minBookable,
  minPrice,
  price,
  priceLabel,
  priceLabelClass,
  productName,
  productType,
  quantity,
  slug,
  updateCartItemDetail
}) => (
  <div className='flex flex-col gap-1'>
    <a
      href={`/products/${slug}`}
      className={`text-lg font-medium ${cartItemTitleClass}`}
    >
      <Text
        {...{
          content: productName
        }}
      />
    </a>
    <div className='flex gap-6'>
      <SpinnerWithInput
        {...{
          availableDiscount,
          categories,
          disabled: !isCartEditable || !isSpinnerInputReadOnly,
          id,
          minPrice,
          pageName: PAGE_NAME.HOST_CART.label,
          price,
          productName,
          productType,
          quantity,
          range: { min: minBookable, max: maxBookable },
          updateCartItemDetail
        }}
      />
      <Text
        {...{
          className: `self-center text-lg font-medium ${priceLabelClass}`,
          content: priceLabel
        }}
      />
    </div>
  </div>
);

const CartItemRowWebOrganism = ({
  cartItemTitle,
  cartItemTitleClass,
  categories,
  deleteItem,
  derivedValues: { unitSellingPrice } = {},
  id,
  isCartEditable = true,
  isSpinnerInputReadOnly,
  itemMediaForCarousel,
  lastCartItem,
  priceLabelClass,
  product: {
    availableDiscount,
    maxBookable,
    minBookable,
    name: productName,
    price: productPrice,
    slug,
    status: productStatus,
    type: productType
  },
  productNotes,
  quantity,
  unitListedPrice,
  updateCartItemDetail
}) => {
  const { minPrice = 0, price } = productPrice;

  const [showModal, setShowModal] = useState(false);
  const priceLabel = getPriceLabel({
    minPrice,
    productType,
    unitListedPrice,
    unitSellingPrice,
    quantity
  });
  return (
    <div className={`${lastCartItem ? '' : 'border-b pb-3'} border-platinum`}>
      <div className='flex gap-4 font-medium'>
        <div className='rounded-2.5'>
          <LineItemMedia {...{ itemMediaForCarousel, isHostCart: true }} />
        </div>
        <div className='flex flex-1 justify-between'>
          <CartItemBody
            {...{
              availableDiscount,
              cartItemTitle,
              cartItemTitleClass,
              categories,
              id,
              isCartEditable,
              isSpinnerInputReadOnly,
              maxBookable,
              minBookable,
              minPrice,
              price,
              priceLabel,
              priceLabelClass,
              productName,
              productStatus,
              productType,
              quantity,
              slug,
              unitListedPrice,
              unitSellingPrice,
              updateCartItemDetail
            }}
          />
          {isCartEditable && (
            <RemoveItemIcon
              {...{ onClick: () => setShowModal(true), productStatus }}
            />
          )}
        </div>
      </div>
      <HostCartProductNotes {...{ productNotes }} />
      {showModal && (
        <ConfirmationModal
          {...{
            modalText: 'Are you sure you want to remove this item?',
            operation: () => deleteItem({ cartItemId: id }),
            setShowModal
          }}
        />
      )}
    </div>
  );
};

export default CartItemRowWebOrganism;
