import { useForm } from 'react-hook-form';

import { UnSavedChangesErrorMessage } from '@/atoms';
import { VIEW_EDIT_MODE } from '@/config/plannerCart';
import { CartSectionHeading, CartSummaryNote } from '@/molecules';

const NoteOrganism = ({
  cartNoteViewEditMode,
  externalNotes,
  id,
  internalNotes,
  isCartEditable,
  setCartNoteViewEditMode,
  showUnSavedChangesErrorMessage,
  title,
  updateCartDetailAPI
}) => {
  const { handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      internalNotes,
      externalNotes
    }
  });

  const onClickEdit = () => {
    setCartNoteViewEditMode(VIEW_EDIT_MODE.EDIT);
  };

  const onClickDiscardChanges = () => {
    reset({ ...{ internalNotes, externalNotes } });
    setCartNoteViewEditMode(VIEW_EDIT_MODE.VIEW);
  };

  const saveChanges = (data) => {
    updateCartDetailAPI({ ...data, id });
    setCartNoteViewEditMode(VIEW_EDIT_MODE.VIEW);
  };

  const summaryNote = [
    {
      noteType: 'EXTERNAL',
      value: externalNotes,
      dbName: 'externalNotes'
    },
    {
      noteType: 'INTERNAL',
      value: internalNotes,
      dbName: 'internalNotes'
    }
  ];

  return (
    <div className='flex flex-col gap-4 my-4'>
      <CartSectionHeading
        {...{
          isCartEditable,
          mode: cartNoteViewEditMode,
          onClickDiscardChanges,
          onClickEdit,
          onClickSaveChanges: handleSubmit(saveChanges),
          title
        }}
      />
      <UnSavedChangesErrorMessage {...{ showUnSavedChangesErrorMessage }} />
      <div className='flex flex-col gap-3'>
        {summaryNote.map(({ noteType, value, dbName }, index) => (
          <CartSummaryNote
            key={`${index}-${noteType}`}
            {...{
              dbName,
              disabled: cartNoteViewEditMode === VIEW_EDIT_MODE.VIEW,
              noteType,
              setValue,
              value
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default NoteOrganism;
