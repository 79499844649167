import Image from 'next/image';
import { useState } from 'react';

import {
  ImageCarouselModal,
  ItemCommonDetails,
  Text,
  UploadCartItemMediaModal
} from '@/atoms';
import {
  CART_ORDER_SOURCE,
  PRODUCT_STATUS,
  ROLE,
  staticMediaStoreBaseURL
} from '@/config/common';
import { getImageURL } from '@/helpers/carousel';
import { getSerializedRichText } from '@/helpers/editor';
import { parseFormatPriceNumberValueFromAPI } from '@/helpers/formatNumber';
import { useIsMobile } from '@/helpers/screenResolutionHelper';
import { LineItemMedia } from '@/molecules';

const { CRM } = ROLE;

const OpenImageModalIndicator = ({ bannerImage, isMobile, openImageModal }) => (
  <div className='absolute z-10 top-2 left-2'>
    <Image
      alt='open product image'
      className='cursor-pointer'
      height={isMobile ? 16 : 22}
      onClick={() => openImageModal(bannerImage)}
      src={`${staticMediaStoreBaseURL}/icons/resize-icon.svg`}
      width={isMobile ? 16 : 22}
    />
  </div>
);

const ItemMediaForCartRO = ({
  thumbnailImage,
  isMobile,
  setImageCarouselModalImage
}) => (
  <>
    <OpenImageModalIndicator
      {...{
        bannerImage: thumbnailImage,
        isMobile,
        openImageModal: setImageCarouselModalImage
      }}
    />
    <Image
      alt='user'
      src={getImageURL(thumbnailImage)}
      layout='fill'
      objectFit='cover'
      className='rounded-l-lg'
      onClick={() => setImageCarouselModalImage(thumbnailImage)}
    />
  </>
);

const ItemSummaryCardOrganism = ({
  id,
  itemMediaForCarousel,
  productName,
  productNotes,
  productSource,
  productStatus,
  quantity,
  role,
  setItemInDetailView,
  showProductMedia,
  unitListedPrice
}) => {
  const itemTextClass =
    productStatus === PRODUCT_STATUS.UNPUBLISHED
      ? 'text-error-base'
      : 'text-rich-black';

  const [isMobile] = useIsMobile();
  const [cartItemIdForUploadMediaModal, setCartItemIdForUploadMediaModal] =
    useState(null);

  const [imageCarouselModalImage, setImageCarouselModalImage] = useState(false);
  const thumbnailImage = itemMediaForCarousel[0];
  const isCRM = role === CRM;

  const itemMediaMap = {
    [CART_ORDER_SOURCE.CART]: ItemMediaForCartRO,
    [CART_ORDER_SOURCE.ORDER]: isCRM ? LineItemMedia : ItemMediaForCartRO
  };
  const isProductMediaHiddenAndRolePlanner = !showProductMedia && isCRM;
  const ItemMedia = itemMediaMap[productSource];

  return (
    <div>
      <div className='flex gap-4 rounded-xl shadow-card border border-neutral cursor-pointer h-24 md:h-36'>
        <div
          className={`w-28 relative md:w-40 h-full flex flex-col self-center ${
            isProductMediaHiddenAndRolePlanner
              ? 'rounded-l-lg border-2 border-orange'
              : ''
          }`}
        >
          {cartItemIdForUploadMediaModal && (
            <UploadCartItemMediaModal
              {...{
                cartId: id,
                cartItemId: cartItemIdForUploadMediaModal,
                setCartItemIdForUploadMediaModal
              }}
            />
          )}
          <ItemMedia
            {...{
              cartItemId: id,
              isMobile,
              itemMediaForCarousel,
              productName,
              productSource,
              setCartItemIdForUploadMediaModal,
              setImageCarouselModalImage,
              showProductMedia,
              thumbnailImage,
              unAvailableCartItem: true
            }}
          />
        </div>
        <div
          className='flex flex-1 justify-between'
          onClick={() => setItemInDetailView(id)}
        >
          <div className='flex flex-col self-center md:gap-2'>
            <Text
              {...{
                className: `font-medium text-xs md:text-base max-w-28 md:max-w-116.5 truncate ${itemTextClass}`,
                content: productName
              }}
            />
            <ItemCommonDetails
              {...{
                containerClass: itemTextClass,
                priceLabel: parseFormatPriceNumberValueFromAPI(
                  unitListedPrice * quantity
                ),
                quantity
              }}
            />
            {getSerializedRichText(productNotes) && (
              <div className={`${itemTextClass} flex gap-1`}>
                <Text
                  content='Details: '
                  className='text-xs md:text-base text-rich-black font-medium'
                />
                <Text
                  {...{
                    content: getSerializedRichText(productNotes),
                    className:
                      'text-xs md:text-base font-light max-w-28 md:max-w-116.5 truncate h-5'
                  }}
                />
              </div>
            )}
          </div>
          <div className='self-center ml-auto mr-4 flex h-32 md:h-36'>
            <Image
              alt='user'
              height={isMobile ? 16 : 32}
              src={`${staticMediaStoreBaseURL}/icons/right-angle-red-bg.svg`}
              width={isMobile ? 16 : 32}
            />
          </div>
        </div>
      </div>
      {imageCarouselModalImage && (
        <ImageCarouselModal
          {...{
            images: itemMediaForCarousel,
            modalImage: imageCarouselModalImage,
            openImageModal: setImageCarouselModalImage
          }}
        />
      )}
    </div>
  );
};

export default ItemSummaryCardOrganism;
