import { Button, CartItemCTA, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/helpers/formatNumber';
import { LineItemMedia } from '@/molecules';
import { getSelectedPartner } from '@/services/plannerCart.service';

const PriceAwaitedCartItemOrganism = ({
  cartId,
  cartItemIdInViewEditMode,
  haflaMarginPercent,
  id,
  isCartEditable,
  itemMediaForCarousel,
  onClickCloneCartItem,
  onDeleteCartItemImage,
  partnerId,
  product,
  quantity,
  sellingPrice,
  setCartItemIdForUploadMediaModal,
  setCartItemIdInViewEditMode,
  showProductMedia,
  unitListedPrice,
  verifyAndSetItemInRequestToDeleteMode,
  updateCartItemMediaSortOrder
}) => {
  const unlockCTA = cartItemIdInViewEditMode !== id;
  const {
    name,
    price: { minPrice },
    slug,
    productPartners
  } = product;

  return (
    <div className='w-full'>
      <div className='flex gap-5 text-sm font-light py-3 border-b border-platinum bg-lightgray px-4 rounded-t-lg'>
        <div className='flex-1 flex gap-3 self-center'>
          <LineItemMedia
            {...{
              cartId,
              cartItemId: id,
              isCartEditable,
              itemMediaForCarousel,
              onDeleteCartItemImage,
              productName: name,
              setCartItemIdForUploadMediaModal,
              showProductMedia,
              updateCartItemMediaSortOrder
            }}
          />
          <a
            href={`/products/${slug}`}
            className='self-center'
          >
            <Text
              {...{
                content: name,
                className: 'self-center flex-1 flex'
              }}
            />
          </a>
        </div>

        <Text
          {...{
            content: `Price starting from AED ${parseFormatPriceValueFromAPI(
              minPrice
            )}`,
            className: 'w-40 self-center'
          }}
        />
        <Text {...{ content: quantity, className: 'w-20 self-center' }} />
        <Text
          {...{
            content: parseFormatPriceValueFromAPI(unitListedPrice),
            className: 'w-24 self-center'
          }}
        />
        <Text
          {...{
            content: parseFormatPriceValueFromAPI(sellingPrice),
            className: 'w-30 self-center'
          }}
        />
        <Text
          {...{
            content: getSelectedPartner({ productPartners, partnerId }),
            className: 'w-40 self-center'
          }}
        />
        <Text
          {...{
            content: `${parseFormatPriceValueFromAPI(haflaMarginPercent)} %`,
            className: `w-20 self-center ${
              Number(parseFormatPriceValueFromAPI(haflaMarginPercent)) >= 0
                ? 'text-green'
                : 'text-red-500'
            }`
          }}
        />
        <div className='w-40 self-center flex gap-3'>
          <Button
            {...{
              children: 'View',
              width: 'w-20',
              className:
                'bg-gradient-to-r h-8 from-light-orange via-dark-orange to-rose text-white px-2 py-2 rounded-lg text-sm font-medium',
              iconGroupClassName: 'reduce-gap',
              iconHeight: 15,
              iconWidth: 15,
              iconPosition: 'Left',
              iconUrl: `${staticMediaStoreBaseURL}/icons/view-icon.svg`,
              onClick: () => unlockCTA && setCartItemIdInViewEditMode(id)
            }}
          />
          <CartItemCTA
            {...{
              type: 'cloneCartItem',
              className: 'self-center',
              onClick: () => onClickCloneCartItem({ cartItemId: id })
            }}
          />
          <CartItemCTA
            {...{
              type: 'delete',
              onClick: () =>
                isCartEditable &&
                unlockCTA &&
                verifyAndSetItemInRequestToDeleteMode(id),
              className: `self-center ${
                isCartEditable && cartItemIdInViewEditMode !== id
                  ? ''
                  : 'pointer-events-none opacity-40'
              }`
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceAwaitedCartItemOrganism;
