import { EventCartOrderBanner, EventCartOrderDetails } from '@/molecules';

const EventCartOrderInfoOrganism = ({
  cartOrderBannerDetail,
  userEventDetails
}) => (
  <div className='mb-6'>
    <EventCartOrderBanner {...cartOrderBannerDetail} />
    <EventCartOrderDetails {...userEventDetails} />
  </div>
);

export default EventCartOrderInfoOrganism;
