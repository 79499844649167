import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CTA } from '@/atoms';
import { convertToDateFormat } from '@/lib/time';
import { yupValidator } from '@/lib/yup-validator';
import { RequestForQuoteModal } from '@/molecules';

const RequestPendingItemListOrganism = ({
  CartItemRow,
  cartItemTitleClass,
  checkoutEventList,
  deleteItem,
  isCartEditable = true,
  isRequestForQuote,
  isValidForRequestForQuote,
  itemList = [],
  onClickRequestForQuote,
  priceLabelClass,
  setIsRequestForQuote,
  updateCartItemDetail,
  userEventSummary: { userEventDetails: [userEventDetails] = [] } = {}
}) => {
  const {
    eventContactName,
    eventContactEmail,
    eventTitle,
    checkoutEvent: { id: eventId, eventId: checkoutEventId } = {},
    eventDate
  } = userEventDetails || {};

  const yupResolver = yupValidator(
    yup.object().shape({
      eventType: yup.string().required('Event type is mandatory.'),
      festivalName: yup.string().required('Festival name is mandatory.')
    })
  );

  const {
    register,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
    control,
    handleSubmit
  } = useForm({
    resolver: yupResolver,
    defaultValues: {
      checkoutEventId,
      eventDate: eventDate
        ? convertToDateFormat(eventDate, 'MM/DD/YYYY')
        : Date.now(),
      eventType: eventId,
      email: eventContactEmail,
      name: eventContactName,
      festivalName: eventTitle
    }
  });

  const [selectedEventDate, setSelectedEventDate] = useState(
    convertToDateFormat(eventDate, 'MM/DD/YYYY') || Date.now()
  );
  return (
    <div className='border border-red-500 px-3 py-4 md:p-6 rounded-2.5 flex flex-col gap-3'>
      {itemList.map((items, index) => (
        <CartItemRow
          key={index}
          {...{
            ...items,
            cartItemTitleClass,
            deleteItem,
            isCartEditable,
            priceLabelClass,
            updateCartItemDetail
          }}
        />
      ))}
      {isCartEditable && (
        <div className='flex justify-end'>
          <CTA
            {...{
              buttonColor:
                'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white',
              children: 'Request for quote',
              className: 'text-sm font-medium w-40 py-2',
              disabled: isValidForRequestForQuote,
              onClick: () => setIsRequestForQuote(true)
            }}
          />
        </div>
      )}
      {isRequestForQuote && (
        <RequestForQuoteModal
          {...{
            checkoutEventList,
            clearErrors,
            control,
            errors,
            eventDate: selectedEventDate,
            getValues,
            handleSubmit,
            isRequestForQuote,
            onClickRequestForQuote,
            register,
            setIsRequestForQuote,
            setSelectedEventDate,
            setValue
          }}
        />
      )}
    </div>
  );
};

export default RequestPendingItemListOrganism;
