import _ from 'lodash';
import { useState } from 'react';

import { BID_STATUS } from '@/config/bid';
import copyToClipBoard from '@/helpers/copyToClipBoard';
import {
  CartCardBody,
  CartCardHeader,
  PDFDownloadConfirmationModal
} from '@/molecules';
import { HOST_PORTAL_URL } from '@/services/connections.service';
import { downloadQuotationPDF } from '@/services/invoiceQuotationCommon.service';
import { getDispatchCTALabel } from '@/services/tender.service';

const getReadOnlyCartURLForCustomer = (cartID) =>
  `${HOST_PORTAL_URL}/host-carts/${cartID}/quote`;

const CartCardOrganism = ({
  authUser,
  cartListType,
  cartName,
  cartNumber,
  cartOrder,
  createdAt,
  createNewTenderAndPublishBids,
  deliveryDate,
  id,
  invokeArchiveCart,
  isArchivedCart,
  isCRM,
  onClickCloneCart,
  opsUser,
  pickupDate,
  router,
  setEditCartSummaryModal,
  setShowToast,
  showCreateTenderAndDispatchBidsCTA,
  status,
  tenders,
  updatedAt
} = {}) => {
  const dispatchCTALabel = getDispatchCTALabel({ tenders });
  const cartCreatedBy = opsUser?.name || 'NA';

  const [
    showPDFDownloadConfirmationModal,
    setShowPDFDownloadConfirmationModal
  ] = useState(false);

  const onClickCopyQuoteLink = (e) => {
    e.stopPropagation();
    copyToClipBoard(getReadOnlyCartURLForCustomer(id));
    setShowPDFDownloadConfirmationModal(false);
    setShowToast({
      show: true,
      status,
      message: 'Web Quote Link Copied to ClipBoard'
    });
  };

  const onClickDownloadPDF = () => {
    setShowPDFDownloadConfirmationModal(false);
    downloadQuotationPDF({
      cartId: id,
      isStandardTypeSelected: true,
      userCartId: authUser?.id
    });
  };

  const isActiveTenderAvailable = _.some(tenders, {
    status: BID_STATUS.ACTIVE.value
  });

  return (
    <div
      className={`py-5 my-5 md:border text-sm md:text-base border-platinum rounded-lg px-4 md:px-6 bg-lightgray font-medium hover:border-cinnabar shadow-event cursor-pointer`}
    >
      <CartCardHeader
        {...{
          cartName,
          cartNumber,
          cartOrder,
          createdAt,
          createNewTenderAndPublishBids,
          deliveryDate,
          dispatchCTALabel,
          id,
          invokeArchiveCart,
          isActiveTenderAvailable,
          isArchivedCart,
          onClickCloneCart,
          pickupDate,
          router,
          setEditCartSummaryModal,
          setShowPDFDownloadConfirmationModal,
          showCreateTenderAndDispatchBidsCTA,
          showEditCartInformationCTA: isCRM,
          status,
          updatedAt
        }}
      />
      <CartCardBody
        {...{
          authUser,
          cartCreatedBy,
          cartListType,
          cartOrder,
          createdAt,
          id,
          isCRM,
          onClickCopyQuoteLink,
          setShowPDFDownloadConfirmationModal,
          setShowToast,
          status
        }}
      />
      <PDFDownloadConfirmationModal
        {...{
          onClickDownloadPDF,
          onClickCopyQuoteLink,
          setShowPDFDownloadConfirmationModal,
          showPDFDownloadConfirmationModal
        }}
      />
    </div>
  );
};

export default CartCardOrganism;
