import * as HttpService from '@/services/http.service';
import { APPLY_PROMO_CODE, REMOVE_PROMO_CODE } from '@/services/url.service';

export const applyPromoCode = ({ userCartId, cartId, promoCode }) =>
  HttpService.postWithoutAuth(APPLY_PROMO_CODE, {
    userCartId,
    cartId,
    promoCode
  });

export const removePromoCode = ({ userCartId, cartId, promoCodeId }) =>
  HttpService.postWithoutAuth(REMOVE_PROMO_CODE, {
    userCartId,
    cartId,
    promoCodeId
  });
